/* You can add global styles to this file, and also import other style files */

:root {
    --space: 20px;
    --gray: #707070;
    --lightGray: #f5f5f5;
    --gifBlue: #0e1f9f;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline,
::ng-deep .mat-form-field-appearance-outline,
::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
    color: var(--gifBlue) !important;
}

html,
body {
    height: 100%;
    color: var(--gray);
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3 {
    margin-top: 0;
    font-weight: 400;
}
h1 {
    font-size: 40px;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 30px;
}

.spacer {
    flex: 1 1 auto;
}

.myData {
    display: flex;
    gap: 0 30px;
    flex-wrap: wrap;

    .mat-form-field,
    .myField {
        width: calc(25% - 30px);
    }

    &.cols5 {
        .mat-form-field,
        .myField {
            width: calc(20% - 30px);
        }

        .w2 {
            width: calc(40% - 30px);
        }
    }

    .myField .mat-form-field {
        width: 100%;
    }

    .mat-input-element[type="date"]::after {
        content: inherit;
    }
}

.mat-slider {
    width: 100%;
}

app-navigation {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.warn {
    border: 1px red solid;
    padding: 20px;
}
.textWarn {
    color: red;
}

.selectPanelSink {
    min-width: 400px !important;
}

.mat-select-panel.sinkCodeSelect {
    width: 600px;
    max-width: 600px;
}

.tableWithInfo {
    margin-bottom: 20px;
}

.info {
    color: red;
    margin-bottom: 40px;
}

.buttons {
    display: flex;
    gap: 20px;

    &.right {
        justify-content: flex-end;
    }
}

.mat-column-edit,
.mat-column-print,
.mat-column-delete,
.mat-column-details,
.mat-column-type {
    width: 40px;
}

.mat-column-index {
    width: 60px;
}

.mat-column-delete {
    color: rgb(145, 11, 11);
}

.innerSection {
    width: 1400px;
    margin: auto;
    padding: 0 20px;
}
